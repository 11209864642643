import React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faVideo, faVolumeUp, faBook, faNewspaper, faPlay } from '@fortawesome/free-solid-svg-icons';

const MediaTypeIcon = ({ types }) => {

  const icons = {
    'Photo': faImage,
    'Newspaper article': faNewspaper,
    'Video': faVideo,
    'Journal': faBook,
    'Audio': faVolumeUp,
    'Printed Material': faImage,
    'Painting': faImage,
  }

  return (
    <div className="media-type-icon">
      {types.map((mediaText, index) =>
        <span key={`${mediaText}-${index}`}>
          <FontAwesomeIcon icon={icons[mediaText]} />
          {mediaText && (
            <span className="media-text">
              <small>
                <em>{mediaText}</em>
              </small>
            </span>
          )}
        </span>
      )}
    </div>
  );
};

const SearchMediaCard = ({ media, currentSearchQuery }) => {
  const {
    id,
    story_name,
    media_type,
    caption,
    display_date,
    image,
    decade,
    description,
    thumbnail_url,
    media_id,
    preview_url,
    color_scheme,
    remote_media_img,
    remote_video_thumbnail
    // source,
  } = media;

  console.log(media);

  var content = caption ? caption.replace(/(<([^>]+)>)/gi, '') : description.replace(/(<([^>]+)>)/gi, '');

  const excerpt = _.truncate(content, { length: 100 });

  const thumbnail = image !== null ? image.featured : thumbnail_url;

  const thumbnailCDN = _.replace(thumbnail, 'https://api.bcah.ca', 'https://bc-timeline-backend.azureedge.net');

  const button_label = media_type.indexOf('Video') !== -1 ? 'Watch Full Video' : 'Learn More';

  return (
    <Link
      to={`/${_.kebabCase(decade[0])}/${_.kebabCase(_.split(story_name, '.').join(''))}#${id}`}
      state={{ fromSearch: true, fromSearchUrl: currentSearchQuery }}
    >
      <div className={`search-story-card search-media-card ${color_scheme}`}>
        {/* {thumbnail && ( */}
        <div
          className="search-story-featured-image"
          style={{
            backgroundImage: `url(${thumbnailCDN})`,
          }}
        >
          {media_type && <MediaTypeIcon types={media_type} />}
        </div>
        {/* )} */}
        <div className="story-info">
          <small className="story-title">
            <span className="site-label"><strong>Related Story: </strong></span>
            <span className="title">{story_name}</span>
          </small>
          {display_date && <p className="font-italic">{display_date}</p>}
          {excerpt && (
            <div className="story-content">
              <p>{excerpt}</p>
            </div>
          )}

          <button type="button" className="explore-arrow">
            <span className="label">{button_label}</span>
            <span className="long-arrow right-arrow" />
          </button>
        </div>
      </div>
    </Link>
  );
};

export default SearchMediaCard;
