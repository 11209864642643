import React from 'react';
// import gsap from 'gsap';
import { Link } from 'gatsby';
import _ from 'lodash';


const SearchStoryCard = ({ story, currentSearchQuery }) => {
  const {
    name,
    story_content,
    display_date,
    featured_image,
    decade,
    color_scheme
  } = story;

  const content = story_content.replace(/(<([^>]+)>)/gi, '');

  const excerpt = _.truncate(content, { length: 150 });

  // const reveal = (entry, node) => {
  //   return gsap.from(
  //     node.querySelectorAll('.story-header-wrapper .explore-arrow, .story-heading, .story-deck, .story-date, .story-tags, .story-slider-wrapper, .story-content-wrapper'),
  //     {
  //       opacity: 0,
  //       y: '+=50',
  //       duration: .5,
  //       stagger: 0.1,
  //       force3D: true,
  //       ease: "power1.easeInOut"
  //     },
  //   )
  // }

  return (
    <Link
      to={`/${_.kebabCase(decade[0])}/${_.kebabCase(_.split(name, '.').join(''))}`}
      state={{ fromSearch: true, fromSearchUrl: currentSearchQuery }}
      // entry={{
      //   delay: .5,
      //   trigger: ({ entry, node }) => reveal(entry, node),
      // }}
    >
      <div className={`search-story-card ${color_scheme}`}>
        {featured_image && (
          <div
            className="search-story-featured-image"
            style={{
              backgroundImage: `url(${_.replace(featured_image[0].image.featured, 'https://api.bcah.ca', 'https://bc-timeline-backend.azureedge.net')})`,
            }}
          />
        )}
        <div className="story-info">
          <h1 className="story-title h4 text-uppercase">{name}</h1>
          {display_date && <p className="font-italic">{display_date}</p>}
          {excerpt && (
            <div className="story-content">
              <p dangerouslySetInnerHTML={{ __html: excerpt }}></p>
            </div>
          )}

          <span className="explore-arrow">
            <span className="label">Read Story</span>
            <span className="long-arrow right-arrow" />
          </span>
        </div>
      </div>
    </Link>
  );
};

export default SearchStoryCard;
