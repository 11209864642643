import React, { useState, useEffect } from 'react';
import { globalHistory } from "@reach/router";
import { graphql, Link } from 'gatsby';
import gsap from 'gsap';
import queryString from 'query-string';
import _ from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SearchStoryCard from '../components/search/story';
import SearchMediaCard from '../components/search/media';
import Filter from '../components/filter';
import Footer from '../components/footer';
import SocialShare from '../components/socialShare';

const searchResultLoadMore = (arr, startIndex = 0, lastIndex) => {
  return arr.slice(startIndex, lastIndex);
};

const Search = ({ location, data }) => {
  const isSearch = location.pathname.indexOf('search') > -1 && true;

  const filterVals = queryString.parse(location.search, {
    arrayFormat: 'comma',
  });

  const keys = Object.keys(filterVals);
  const filterBy = {};

  for (var i = 0; i < keys.length; i++) {
    filterBy[keys[i]] = _.isArray(filterVals[keys[i]])
      ? filterVals[keys[i]]
      : [filterVals[keys[i]]];
  }

  const filteredStories = [];
  const filteredMedias = [];
  let result;
  let filteredResults = [];
  const filteredItems = [];
  let allSearchResults = {};
  const allFilteredResults = []

  data.allStories.edges.map((item) => filteredStories.push(item.node));

  // gets all possible combinations of filtered values
  const getCombinations = (filters, filterIndex, results, current) => {
    const allKeys = Object.keys(filters);
    const optionKey = allKeys[filterIndex];

    const vals = filters[optionKey];

    if (typeof vals !== 'undefined') {
      for (var i = 0; i < vals.length; i++) {
        current[optionKey] = vals[i];

        if (filterIndex + 1 < allKeys.length) {
          getCombinations(filters, filterIndex + 1, results, current);
        } else {
          // The easiest way to clone an object.
          const res = JSON.parse(JSON.stringify(current));
          results.push(res);
        }
      }
    }

    return results;
  };


  // Get all combinations of all filter search
  const filterByCombination = getCombinations(filterBy, 0, [], {});
  // for each combination
  filterByCombination && _.forEach(filterByCombination, (filter) => {

    const filterOption = {
      ...(filter.decade) && { decade: [filter.decade] },
      ...(filter.geographic_area) && { geographic_area: [filter.geographic_area] },
      ...(filter.story_stream) && { story_stream: [filter.story_stream] },
      ...(filter.heritage) && { heritage: [filter.heritage] },
      ...(filter.keywords) && { keywords: [filter.keywords] }
    };

    // filter through all stories, depending on filterOption values
    // if filterOption is empty, it will return all stories
    result = _.filter(filteredStories, filterOption);

    // if there's result for each combination
    if (result.length > 0) {
      // get each and push to new array
      return _.map(result, (story) => filteredItems.push(story));
    }
  });

  // remove duplicates
  filteredResults = _.uniq(filteredItems);

    // if a media type is selected
    if (filterBy.media_type) {
      // filter through the search result
      // get name, decade and media lists of each story
      _.map(filteredResults, ({ name, decade, media, color_scheme }) =>
        // filter through media lists
        _.map(media, (item) =>
          // get selected media type
          _.some(filterBy.media_type, (k) => {
            // if each media has media type selected and it matches some media type
            if (item.media_type !== null && item.media_type.indexOf(k) !== -1) {
              // push matched media to a new array
              return filteredMedias.push({
                story_name: name,
                color_scheme: color_scheme,
                decade,
                ...item,
              });
            }

            return false;
          })));

      // media results
      // group it by decade
      allSearchResults = _.groupBy(filteredMedias, 'decade');
    } else {
      // story results
      // group it by decade
      allSearchResults = _.groupBy(filteredResults, 'decade');
    }



  if (globalHistory.location.pathname === '/search/' && !location.search) {
    allSearchResults = _.groupBy(filteredStories, 'decade');
    // console.log('all search result');
  }

  const reorderedDecades = data.allDecades.nodes.map(decade => decade.value).sort((a, b) => {
    if (a.indexOf('Pre-') !== -1) return -1;
    if (b.indexOf('Pre-') !== -1) return 1;
  })

  let totalSearchResults = 0;

  for (var index = 0; index < reorderedDecades.length; index++) {
    const decade = reorderedDecades[index];

    if (allSearchResults[decade] !== undefined) {
      allFilteredResults.push({ [decade]: allSearchResults[decade] })

      // Get total of search results
      totalSearchResults += allSearchResults[decade].length
    }
  }

  const deleteSelectedFilter = (remove, name) => {
    let res; // new search filter
    const removeIndex = filterBy[name].indexOf(remove); // index of removed item

    filterBy[name].splice(removeIndex, 1);

    if (filterBy[name].length === 0 && filterBy[name].indexOf(remove) === -1) {
      res = Object.keys(filterBy).reduce((obj, k) => {

        if (k !== name) {
          obj[k] = filterBy[k];
        }

        return obj;
      }, {});

    } else {
      res = { ...filterBy, [name]: filterBy[name] };
    }

    const newSearchQuery = queryString.stringify(res, { arrayFormat: 'comma' });
    const searchQueryUrl = newSearchQuery
      ? `/search?${newSearchQuery}`
      : '/search';

    window.location.href = searchQueryUrl;
    window.setTimeout(() => window.scrollTo(0, 0), 100);
  };

  console.log(allFilteredResults);

  // State for the list
  const currentList = searchResultLoadMore(allFilteredResults, 0, 2);

  const [list, setList] = useState(currentList);

  const [loadMore, setLoadMore] = useState(false);

  const [hasMore, setHasMore] = useState(_.keys(allFilteredResults).length > 2);

  const handleLoadMore = () => {
    setLoadMore(true);
  };


  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = _.keys(list).length;
      const isMore = currentLength < _.keys(allFilteredResults).length;
      const nextResult = isMore
        ? searchResultLoadMore(
          allFilteredResults,
          currentLength,
          currentLength + 2,
        )
        : [];

      setList([...list, ...nextResult]);

      setLoadMore(false);
    }
  }, [loadMore, hasMore, allFilteredResults, list]);

  useEffect(() => {
    const isMore = _.keys(list).length < _.keys(allFilteredResults).length;
    setHasMore(isMore);
  }, [list, allFilteredResults]);


  const loadMoreResult = _.omit(allFilteredResults, _.keys(list));
  const moreResults = []

  _.map(loadMoreResult, (stories) => _.map(stories, (story) => moreResults.push(story)))

  const loadMoreTotal = moreResults.reduce((a, b) => {
    return a + b.length;
  }, 0)

  const goBack = () => {
    window.history.back();
  };

  let shareSearch = [];
  _.keys(filterBy).map((k) => (shareSearch = _.concat(shareSearch, filterBy[k])));

  const dataOptions = {
    url: location.href,
    title: `${globalHistory.location.pathname === '/search/' && !location.search ? `All Archives` : `Search Results: ${shareSearch
      .join(', ')
      .replace(/,(?!.*,)/gim, ' and')}`} - ${data.site.siteMetadata.title}`,
  };

  return (
    <Layout isSearchPage={isSearch}>
      <SEO
        title={dataOptions.title}
        url={dataOptions.url}
      />

      <div className="search-wrapper main-fixed-wrapper">
        <div className="search-header-container reveal-animate">
          <Container fluid="lg">
            <Row className="align-items-center">
              <Col lg={3} md={3}>
                <button
                  type="button"
                  onClick={goBack}
                  className="back-link  mb-5 mb-md-0"
                >
                  <span className="explore-arrow position-relative">
                    <span className="long-arrow left-arrow" />
                    <span className="label">Go Back</span>
                  </span>
                </button>
              </Col>
              <Col>
                <h1 className="search-heading text-center text-md-right text-uppercase">
                  Results For:
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid="lg">
          <Row>
            <Col lg={8}>
              <div className="filter-result-wrapper reveal-animate text-center text-md-left">
                <p className="text-muted">
                  {totalSearchResults}
                  {totalSearchResults > 0 ? ' results' : ' result'}
                </p>

                {filterBy && (
                  <div className="selected-filters-wrapper">
                    {_.keys(filterBy).map((k) => _.map(filterBy[k], (filter) => (
                      <button
                        key={filter}
                        type="button"
                        value={filter}
                        name={filter}
                        onClick={() => deleteSelectedFilter(filter, k)}
                      >
                        <span>{filter}</span>
                        <FontAwesomeIcon icon={faMinus} size="sm" className="icon" />
                      </button>
                    )))}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            {totalSearchResults > 0 ? (
              <Col lg={8}>
                {_.map(list, (allStories) => _.map(allStories, (stories, decade) => (
                  <div
                    key={decade}
                    className="search-decade-wrapper reveal-animate search-card mb-5 mt-3 pt-4"
                  >
                    <div className="search-decade-heading reveal-animate my-5">
                      <Row className="align-items-center">
                        <Col>
                          <h2 className="script-heading">{decade}</h2>
                        </Col>
                        <Col>
                          <Link to={`/${_.kebabCase(decade)}`} className="story-link">
                            <span className="explore-arrow">
                              <span className="label"> View Decade</span>
                              <span className="long-arrow right-arrow" />
                            </span>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      {_.map(stories, (story, index) => (
                        <Col
                          className="search-column reveal-animate"
                          xs={12}
                          sm={6}
                          key={index}
                        >
                          {!story.media_type ? (
                            <SearchStoryCard
                              story={story}
                              currentSearchQuery={`${location.pathname}${location.search}`}
                            />
                          ) : (
                            <SearchMediaCard
                              media={story}
                              currentSearchQuery={`${location.pathname}${location.search}`}
                            />
                          )}
                        </Col>
                      ))}
                    </Row>
                  </div>
                )))}

                {hasMore && (
                  <div id="load-more" className="reveal-animate">
                    <button type="button" onClick={handleLoadMore}>
                      Load More
                      {loadMoreTotal > 0 && (
                        <span>
                          (
                          {loadMoreTotal}
                          )
                        </span>
                      )}
                    </button>
                  </div>
                )}
              </Col>
            ) : (
              <Col lg={8}>
                <p className="text-center reveal-animate">
                  Sorry, no matches found for <strong>{Object.values(filterVals).join(', ')}</strong>. Please try again.
                </p>
              </Col>
            )}

            <Col lg={4} xl={{ span: 3, offset: 1 }}>
              <Filter checkedItems={filterBy} />
            </Col>
          </Row>
        </Container>
      </div>
      <SocialShare dataOptions={dataOptions} />
      <Footer>
        <div id="footer-color" />
      </Footer>
    </Layout>
  );
};

export default Search;

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }

    allDecades(sort: {fields: value, order: ASC}) {
      nodes {
        value
      }
    }

    allStories(sort: { fields: decade, order: ASC }) {
      edges {
        node {
          story_id
          featured_image {
            image {
              featured
            }
          }
          name
          decade
          display_date
          geographic_area
          heritage
          story_stream
          topics_themes
          story_content
          color_scheme
          keywords
          media {
            id
            media_type
            image {
              featured
            }
            caption
            display_date
            description
            media_id
            preview_url
            embed_url
            thumbnail_url
          }
        }
      }
    }
  }
`;
